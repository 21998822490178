import React from "react";
import "./app.scss";
import { Header } from "./components/header/header";
import { Stake } from "./components/stake/stake";
import { About } from "./components/about/about";

// import default style
import "./styles/theme.less";

export const App = () => {
  return (
    <div className="app">
      <Header />
      <Stake />
      <About />
    </div>
  );
};
