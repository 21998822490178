import React, { useState } from "react";
import { Button, Modal, Notification } from "rsuite";
import QRCode from "qrcode.react";
import Copy from "../../svg/copy.svg";
import "./card.scss";

const copyToClipboard = (content: any) => {
  const el = document.createElement("textarea");
  el.value = content;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);

  Notification.success({
    title: `Copied to clipboard`,
    duration: 1500
  })
};

export const Card = (props: any) => {
  const [visible, setVisiable] = useState(false);

  return (
    <div>
      <div className="flip-card card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <div className="logo-img">
              <img
                alt="Stakezilla"
                className="block-logo"
                src={process.env.PUBLIC_URL + "/" + props.blockchainImg}
              />
            </div>
            <div className="blockchain">{props.blockchain}</div>
            <div className="more-info">more info...</div>
          </div>
          <div className="flip-card-back">
            <div className="block-info">
              <h4>
                <a href={props.url} rel="noreferrer" target="_blank">
                  {props.urlFriendly}
                </a>
              </h4>
              <div className="reward">{props.coin}</div>
              <div className="reward">{props.reward}% annual reward</div>
            </div>
            <div className="button-container" >
              <Button appearance="primary" onClick={() => setVisiable(true)}>Stake now</Button>
            </div>
          </div>
        </div>
      </div>
      <Modal size="md" show={visible} onHide={() => setVisiable(false)}>
        <Modal.Header closeButton={false}>
          <Modal.Title>{props.blockchain}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className="qr">
              <QRCode value={props.address} />
            </div>
            <div className="modal-info">
              <div className="delegate">
                Delegate to:{" "}
                <div className="address">
                  {props.address}
                  <img
                    alt="copy"
                    src={Copy}
                    onClick={() => {
                      copyToClipboard(props.address);
                    }}
                  />
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: props.stake,
                }}
              ></div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setVisiable(false)} appearance="primary">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
