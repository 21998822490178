import React from "react";
import { Card } from "../card/card";
import "./stake.scss";

const blockchains = [
  {
    blockchain: "KUSAMA",
    blockchainImg: "logo-kusama.png",
    coin: "KSM",
    url: "https://kusama.network/",
    urlFriendly: "kusama.network",
    address: "EYLWsnmix2ZYGG1jBedDgUhQQ4cAjpbqH7KvggKYACrBKGr",
    stake: `From 
    <a href="https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Frpc.polkadot.io#/staking">Polkadot{.js}</a> 
    app search for "Stakezilla" or delegate to the address above using the tool of your choice.`,
    reward: 15
  },
  {
    blockchain: "SOLANA",
    blockchainImg: "logo-solana.png",
    coin: "SOL",
    url: "https://solana.com/",
    urlFriendly: "solana.com",
    address: "pgbB8erEYkTBKxyuXJRB4HrwtgyKYqeGCQnuPAH8yVw",
    stake: `Delegate to the address above using the tool of your choice.`,
    reward: "14-16"
  },
  {
    blockchain: "POLKADOT",
    blockchainImg: "logo-polka.png",
    coin: "DOT",
    url: "https://polkadot.network/",
    urlFriendly: "polkadot.network",
    address: "12y1zthxxNH7E9T5v7taTswr7Rn24NZZTQ14hKPicT1scfqL",
    stake: `From 
    <a href="https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Frpc.polkadot.io#/staking">Polkadot{.js}</a> 
    app search for "Stakezilla" or delegate to the address above using the tool of your choice.`,
    reward: 15
  },
];

export const Stake = () => {
  return (
    <div className="stake">
      <div className="container">
        <div className="label">STAKE WITH US</div>
        <div className="grid">
          {blockchains.map((binfo, i) => (
            <Card key={i} {...binfo} />
          ))}
        </div>
      </div>
    </div>
  );
};
