import React from "react";
import "./about.scss";

export const About = () => {
  return (
    <div className="container">
      <div className="about ">
        <div className="inner">
          <div className="title">About us</div>
          <br />
          We are a team of experienced developers operating from Spain and
          Romania. We take great pride in running highly secure and reliable
          nodes both in cloud and bare-metal. Our monitoring and alerting
          systems enable us to provide highest uptimes. Our dedication
          guarantees prompt updates, continuous improvement and high efficiency.
          <br />
          <br />
          Get in touch with us at{" "}
          <a href="mailto:zoicas@stakezilla.net">zoicas@stakezilla.net</a>. We
          are a friendly bunch of blockchain enthusiasts!
        </div>
      </div>
    </div>
  );
};
