import React from "react";
import "./header.scss";

export const Header = () => {
  return (
    <div className="header-container">
      <div className="header container">
        <div className="title">STAKEZILLA</div>
        <div className="subtitle s1">
          Stake your assets with our industry standards secure nodes.
        </div>
        <div className="subtitle s2">Improve decentralization.</div>
        <div className="subtitle s3">Earn rewards.</div>
        <img
          alt="Stakezilla"
          className="big-logo"
          src={process.env.PUBLIC_URL + "/stakezilla.png"}
        />
      </div>
    </div>
  );
};
